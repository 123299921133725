import React from "react"
import { GlobalStyles } from "../styles"
import { GlobalNav } from "../components/GlobalNav"

const NotFoundPage = () => {
  return (
    <>
      <GlobalStyles />

      <GlobalNav />
      <h1>Page not found</h1>
    </>
  )
}

export default NotFoundPage
